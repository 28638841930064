import Alpine from 'alpinejs';
import htmx from 'htmx.org';
import Swiper from "swiper/bundle";

// Alpine plugins
import persist from '@alpinejs/persist';
import resize from '@alpinejs/resize'
import focus from '@alpinejs/focus'
import collapse from '@alpinejs/collapse'
import intersect from '@alpinejs/intersect'

// Custom scripts
import menuScripts from './menu-scripts';
import singleDoctor from './single-doctor';

Alpine.plugin(persist);
Alpine.plugin(resize);
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.plugin(intersect);

window.Swiper = Swiper;
window.Alpine = Alpine;
window.htmx = htmx;

Alpine.data('menuScripts', menuScripts);
Alpine.data('singleDoctor', singleDoctor);


if(nonce !== undefined) {
    document.addEventListener('htmx:configRequest', function(event) {
        event.detail.headers['X-WP-Nonce'] = nonce;
    });
}

document.addEventListener('DOMContentLoaded', function() {
    Alpine.start();
});

// Alpine.magic('blockData', (el, { Alpine }) => {
//     // el.parentElement.id is the key of the blockData object
//     let blockData = Alpine.store('blockData')[el.parentElement.id];
//     return blockData;
// });